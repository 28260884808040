import React, { useState } from "react";
import "./Navbar.css";

export default function Navbar() {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const openResume = () => {
    // Replace "path/to/resume.pdf" with the actual path to your PDF file
    const resumePath = "https://ik.imagekit.io/sti8lrsqsa/Rahul_Singh_Chauhan_Resume.pdf?updatedAt=1708195437451";
    window.open(resumePath, "_blank");
    closeMenu(); // Close the menu after opening the resume
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <a href="/" className="brand-link">
          Rahul Singh Chauhan
        </a>
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <ul className={`menu ${menuVisible ? "open" : ""}`}>
        <li className="menu-item">
          <a href="#Home" onClick={closeMenu}>
            Home
          </a>
        </li>
        <li className="menu-item">
          <a href="#About" onClick={closeMenu}>
            About
          </a>
        </li>
        <li className="menu-item">
          <a href="#Tech_Stack" onClick={closeMenu}>
            Tech Stack
          </a>
        </li>
        <li className="menu-item">
          <a href="#Projects" onClick={closeMenu}>
            Projects
          </a>
        </li>
        <li className="menu-item">
          <a href="#Resume" onClick={openResume}>
            Resume
          </a>
        </li>
      </ul>
    </nav>
  );
}
