// Footer.js

import React , { useEffect, useState } from 'react';
import { FaGithub, FaLinkedin, FaTwitter, FaEnvelope , FaWhatsapp} from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  useEffect(() => {
    // Update the current year when the component is mounted
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="social-icons">
          <a href="https://github.com/rahulsingchauhan " target="_blank" rel="noopener noreferrer">
            <FaGithub />
          </a>
          <a href="https://linkedin.com/in/rahulsingchauhan" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </a>
          <a href="https://twitter.com/rahulsingchauan" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
          <a href="mailto:rahulsinghchauhanrj@gmail.com" target="_blank" rel="noopener noreferrer">
            <FaEnvelope />
          </a>
          <a href="https://wa.me/+918602399308" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp />
          </a>
        </div>
        <div className="contact-info">
          <p>Rahul Singh Chauhan</p>
          <p>Web Developer</p>
          <p>Email: rahulsinghchauhanrj@gmail.com</p>
      
        </div>
      </div>
      <div className="copyright">
        <p>&copy; {currentYear} Rahul Singh Chauhan. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
