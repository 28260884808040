import React from 'react';
import './Aboutme.css';

const Aboutme = () => {
  return (
    <div id='About' className="row">
      <h2 className="about">About me</h2>
      <div className="column column1">
        <h1>I'm Rahul Singh Chauhan</h1>
        <p>
           A{' '}
          <b>
            Certified MERN (MongoDB, Express.js, React.js, Node.js) Stack
            Developer
          </b>{' '}
          with a deep love for creating robust and user-friendly web applications. With a strong background in full-stack development and
          a keen eye for detail, I strive to deliver high-quality solutions that meet both the technical requirements and the end user's needs.
        </p>
      </div>
      <div className="column column2">
        <img
          src="https://media4.giphy.com/media/wsWMbfxtTANFZSqYva/giphy.webp?cid=ecf05e47lmnvkc2cn7utaqlt9jg4z156ktmql9yvwfue756a&ep=v1_gifs_search&rid=giphy.webp&ct=g"
          alt="Owner"
          className="profile-image"
        />
      </div>
    </div>
  );
};

export default Aboutme;
