import React from 'react';
import './Intro.css';

const Intro = () => {
  return (
    <div id="Home" className="Intro">
      <aside>
        <span>Rahul Singh Chauhan</span>
        <br />
        Frontend | Backend | Full Stack
      </aside>
    </div>
  );
};

export default Intro;