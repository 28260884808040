import React from "react";
import Navbar from "./Components/Navbar";
import Intro from "./Components/Intro";
import "./App.css";
import Aboutme from "./Components/Aboutme";
import TechStack from "./Components/TechStack";
import Footer from "./Components/Footer";
import Projects from "./Components/Projects";

const App = () => {
  return (
    <div className="container fade-in"> 
      <Navbar />
      <Intro />
      <Aboutme />
      <TechStack />
      <Projects />
      <Footer />
    </div>
  );
};

export default App;
